import React from 'react';
import {Button} from 'react-bootstrap';
import {t} from '../../../utils/Translator';
import {
    AppService,
    CalendarsService,
    CommandsService,
    ComputedAttributesService,
    DevicesService,
    DriversService,
    GeofencesService,
    GroupsService,
    MaintenancesService,
    NotificationsService,
    PermissionsService,
    UsersService,
    // ExpensesService todo reenable
} from '../../../services';
import Page from '../../Page';

import {default as GeofenecesList} from '../Geofences/list';
import {default as ExpensesList} from '../Expenses/list';
import {default as DevicesList} from '../Devices/list';
import {default as UsersList} from '../Users/list';
import {default as NotificationsList} from '../Notifications/list';
import {default as CalendarsList} from '../Calendars/list';
import {default as ComputedAttributesList} from '../ComputedAttributes/list';
import {default as DriversList} from '../Drivers/list';
import {default as SavedCommandsList} from '../SavedCommands/list';
import {default as MaintenancesList} from '../Maintenances/list';
import {default as GroupsList} from '../Groups/list';
import {Device, Group, User} from '../../../models';
import {App} from "../../../App";

export default class PermissionsToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            selectionDialog: false,
            selectionDialogComponent: null,
        }

        this.renderSelectionDialog = this.renderSelectionDialog.bind(this);
        this.renderToolbar = this.renderToolbar.bind(this);
        this.openSelectionDialog = this.openSelectionDialog.bind(this);
        this.closeSelectionDialog = this.closeSelectionDialog.bind(this);
    }

    openSelectionDialog(name) {
        this.setState({selectionDialog: name})
        this.renderSelectionDialog(name);
    }

    closeSelectionDialog() {
        this.setState({selectionDialog: false, selectionDialogComponent: null})
    }

    renderSelectionDialogTitle(name) {
        // eslint-disable-next-line default-case
        switch (name) {
            case 'geofences':
                return <React.Fragment><h3 className="title"><i
                    className="mdi mdi-map-marker-radius"></i> {t('sharedGeofences')}</h3></React.Fragment>;
            case 'devices':
                return <React.Fragment><h3 className="title"><i className="mdi mdi-car"></i> {t('deviceTitle')}</h3>
                </React.Fragment>;
            case 'users':
                return <React.Fragment><h3 className="title"><i
                    className="mdi mdi-account-multiple"></i> {t('settingsUsers')}</h3></React.Fragment>;
            case 'notifications':
                return <React.Fragment><h3 className="title"><i
                    className="mdi mdi-bell-ring"></i> {t('sharedNotifications')}</h3></React.Fragment>;
            case 'calendars':
                return <React.Fragment><h3 className="title"><i className="mdi mdi-calendar"></i> {t('sharedCalendars')}
                </h3></React.Fragment>;
            case 'computed-attributes':
                return  <React.Fragment><h3 className="title"><i
                        className="mdi mdi-cast"></i> {t('sharedComputedAttributes')}</h3></React.Fragment>
            case 'drivers':
                return <React.Fragment><h3 className="title"><i
                    className="mdi mdi-account-key"></i> {t('sharedDrivers')}</h3></React.Fragment>;
            case 'saved-commands':
                return <React.Fragment><h3 className="title"><i
                    className="mdi mdi-apple-keyboard-command"></i> {t('sharedSavedCommands')}</h3></React.Fragment>;
            case 'maintenance':
                return <React.Fragment><h3 className="title"><i className="mdi mdi-wrench"></i> {t('sharedMaintenance')}
                </h3></React.Fragment>;
            case 'groups':
                return <React.Fragment><h3 className="title"><i
                    className="mdi mdi-google-circles-extended"></i> {t('settingsGroups')}</h3></React.Fragment>;
                    // todo reenable
            // case 'expenses':
            //     return <React.Fragment><h3 className="title"><i
            //         className="mdi mdi-google-circles-extended"></i> {t('expenses')}</h3></React.Fragment>;
        }
        return null;
    }


    renderSelectionDialogList(name) {
        let Component = null;
        let items = {};
        let selectedItems = [];
        let itemsRequest = null;
        let selectItemsRequest = null;
        let parentIdName = 'groupId';
        let listAllFunction = 'list';
        if (this.state.item instanceof User) {
            parentIdName = 'userId';
            listAllFunction = 'listAll';
        } else if (this.state.item instanceof Device) {
            parentIdName = 'deviceId';
        }
        // eslint-disable-next-line default-case
        switch (name) {
            //todo reenable
            // case 'expenses':
            //     Component = ExpensesList;
            //     itemsRequest = ExpensesService[listAllFunction]({}, false);
            //     selectItemsRequest = ExpensesService.list({[parentIdName]: this.state.item.id}, false);
            //     break;
            case 'geofences':
                Component = GeofenecesList;
                itemsRequest = GeofencesService[listAllFunction]({}, false);
                selectItemsRequest = GeofencesService.list({[parentIdName]: this.state.item.id}, false);
                break;
            case 'devices':
                Component = DevicesList;
                itemsRequest = DevicesService[listAllFunction](App.App.user.administrator ? {all: true} : {}, false);
                selectItemsRequest = DevicesService.list({[parentIdName]: this.state.item.id}, false);
                break;
            case 'users':
                Component = UsersList;
                itemsRequest = UsersService[listAllFunction]({}, false);
                selectItemsRequest = UsersService.list({[parentIdName]: this.state.item.id}, false);
                break;
            case 'notifications':
                Component = NotificationsList;
                itemsRequest = NotificationsService[listAllFunction]({}, false);
                selectItemsRequest = NotificationsService.list({[parentIdName]: this.state.item.id}, false);
                break;
            case 'calendars':
                Component = CalendarsList;
                itemsRequest = CalendarsService[listAllFunction]({}, false);
                selectItemsRequest = CalendarsService.list({[parentIdName]: this.state.item.id}, false);
                break;
            case 'computed-attributes':
                Component = ComputedAttributesList;
                itemsRequest = ComputedAttributesService[listAllFunction]({}, false);
                selectItemsRequest = ComputedAttributesService.list({[parentIdName]: this.state.item.id}, false);
                break;
            case 'drivers':
                Component = DriversList;
                itemsRequest = DriversService[listAllFunction]({}, false);
                selectItemsRequest = DriversService.list({[parentIdName]: this.state.item.id}, false);
                break;
            case 'saved-commands':
                Component = SavedCommandsList;
                itemsRequest = CommandsService[listAllFunction]({}, false);
                selectItemsRequest = CommandsService.list({[parentIdName]: this.state.item.id}, false);
                break;
            case 'maintenance':
                Component = MaintenancesList;
                itemsRequest = MaintenancesService[listAllFunction]({}, false);
                selectItemsRequest = MaintenancesService.list({[parentIdName]: this.state.item.id}, false);
                break;
            case 'groups':
                Component = GroupsList;
                itemsRequest = GroupsService[listAllFunction]({}, false);
                selectItemsRequest = GroupsService.list({[parentIdName]: this.state.item.id}, false);
                break;
        }

        Promise.all([itemsRequest, selectItemsRequest]).then(data => {
            items = data[0];

            if (name === 'users' && this.state.item instanceof User) {
                items = items.filter(o => o.id != this.state.item.id);
            }

            selectedItems = data[1].map(o => o.id);

            if (this.state.item instanceof Group && name === 'devices') {
                selectedItems = items.filter(o => o.groupId == this.state.item.id).map(o => o.id);
            }

            this.setState({
                selectionDialog: name,
                selectionDialogComponent:
                name === 'expenses'?
                <Component items={items} onSelect={(item, selected) => {
                }} enableSelection={true} selectedItems={selectedItems} userId={this.state.item.id}/>
                :
                <Component items={items} onSelect={(item, selected) => {
                    this.changePermissionsSelection(item, selected, name)
                }} enableSelection={true} selectedItems={selectedItems} userId={this.state.item.id}/>
            })
        }).catch(ex => AppService.showError(ex))
        return null;
    }

    changePermissionsSelection(item, selected, name) {
        let data = {};
        let parentIdName = 'groupId';
        if (this.state.item instanceof User) {
            parentIdName = 'userId';
        } else if (this.state.item instanceof Device) {
            parentIdName = 'deviceId';
        }
        switch (name) {
            case 'geofences':
                data = {
                    [parentIdName]: this.state.item.id,
                    geofenceId: item,
                };
                break;
                case 'expenses':
                    data = {
                        [parentIdName]: this.state.item.id,
                        expenseId: item,
                    };
                    break;
            case 'devices':
                data = {
                    [parentIdName]: this.state.item.id,
                    deviceId: item,
                };
                break;
            case 'users':
                data = {
                    [parentIdName]: this.state.item.id,
                    managedUserId: item,
                };
                break;
            case 'notifications':
                data = {
                    [parentIdName]: this.state.item.id,
                    notificationId: item,
                };
                break;
            case 'calendars':
                data = {
                    [parentIdName]: this.state.item.id,
                    calendarId: item,
                };
                break;
            case 'computed-attributes':
                data = {
                    [parentIdName]: this.state.item.id,
                    attributeId: item,
                }
                break;
            case 'drivers':
                data = {
                    [parentIdName]: this.state.item.id,
                    driverId: item,
                }
                break;
            case 'saved-commands':
                data = {
                    [parentIdName]: this.state.item.id,
                    commandId: item,
                }
                break;
            case 'maintenance':
                data = {
                    [parentIdName]: this.state.item.id,
                    maintenanceId: item,
                }
                break;
            case 'groups':
                data = {
                    [parentIdName]: this.state.item.id,
                    groupId: item,
                }
                break;
            default:
                return;
        }

        if (this.state.item instanceof Group && name === 'devices') {
            const itemId = this.state.item.id;
            DevicesService.getOne({id: item}, false).then((device) => {
                if (selected) {
                    device.groupId = itemId;
                } else {
                    device.groupId = 0;
                }
                DevicesService.update(device);
            }).catch(ex => {
            })
        } else {
            if (selected) {
                PermissionsService.save(data)
            } else {
                PermissionsService.destroyWithParams(data)
            }
        }


    }


    renderSelectionDialog() {
        if (!this.state.selectionDialog) {
            return null;
        }

        if (!this.state.selectionDialogComponent)
            this.renderSelectionDialogList(this.state.selectionDialog);

        return (
            <Page onHide={this.closeSelectionDialog}
                  title={this.renderSelectionDialogTitle(this.state.selectionDialog)}>
                <div className="container-wrapper grow">
                    <div className="d-flex flex-column h-100 w-100 flex-grow-1 form form-sm overflow">
                        <div className="flex-grow-1 overflow">
                            <div className="container p-0">
                                {this.state.selectionDialogComponent}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }

    isButtonDisabled() {
        return false;
    }

    renderToolbar() {
        return (

            <div className="toolbar d-flex">
                <div className="container">
                    <Button disabled={this.isButtonDisabled()} className="mr-1"
                            onClick={() => this.openSelectionDialog('geofences')} size="sm"><i
                        className="mdi mdi-map-marker-radius"></i><span
                        className="d-none d-lg-inline-block">&nbsp;{t('sharedGeofences')}</span></Button>
                    {(this.state.item instanceof User || this.state.item instanceof Group) ?
                        <Button disabled={this.isButtonDisabled()} className="mr-1"
                                onClick={() => this.openSelectionDialog('devices')} size="sm"><i
                            className="mdi mdi-car"></i><span
                            className="d-none d-lg-inline-block">&nbsp;{t('deviceTitle')}</span></Button> : null}
                    {this.state.item instanceof User ? <Button disabled={this.isButtonDisabled()} className="mr-1"
                                                               onClick={() => this.openSelectionDialog('groups')}
                                                               size="sm"><i
                        className="mdi mdi-google-circles-extended"></i><span
                        className="d-none d-lg-inline-block">&nbsp;{t('settingsGroups')}</span></Button> : null}
                    {this.state.item instanceof User ? <Button disabled={this.isButtonDisabled()} className="mr-1"
                                                               onClick={() => this.openSelectionDialog('users')}
                                                               size="sm"><i
                        className="mdi mdi-account-multiple"></i><span
                        className="d-none d-lg-inline-block">&nbsp;{t('settingsUsers')}</span></Button> : null}
                    <Button disabled={this.isButtonDisabled()} className="mr-1"
                            onClick={() => this.openSelectionDialog('notifications')} size="sm"><i
                        className="mdi mdi-bell-ring"></i><span
                        className="d-none d-lg-inline-block">&nbsp;{t('sharedNotifications')}</span></Button>
                    {this.state.item instanceof User ? <Button disabled={this.isButtonDisabled()} className="mr-1"
                                                               onClick={() => this.openSelectionDialog('calendars')}
                                                               size="sm"><i className="mdi mdi-calendar"></i><span
                        className="d-none d-lg-inline-block">&nbsp;{t('sharedCalendars')}</span></Button> : null}
                      {/* todo reenable {this.state.item instanceof Device ? <Button disabled={this.isButtonDisabled()} className="mr-1"
                                                               onClick={() => this.openSelectionDialog('expenses')}
                                                               size="sm"><i className="mdi mdi-calendar"></i><span
                        className="d-none d-lg-inline-block">&nbsp;{t('expenses')}</span></Button> : null}   */}


                           <Button disabled={this.isButtonDisabled()} className="mr-1"
                                    onClick={() => this.openSelectionDialog('computed-attributes')} size="sm"><i
                                className="mdi mdi-cast"></i><span
                                className="d-none d-lg-inline-block">&nbsp;{t('sharedComputedAttributes')}</span></Button>

                    <Button disabled={this.isButtonDisabled()} className="mr-1"
                            onClick={() => this.openSelectionDialog('drivers')} size="sm"><i
                        className="mdi mdi-account-key"></i><span
                        className="d-none d-lg-inline-block">&nbsp;{t('sharedDrivers')}</span></Button>
                    <Button disabled={this.isButtonDisabled()} className="mr-1"
                            onClick={() => this.openSelectionDialog('saved-commands')} size="sm"><i
                        className="mdi mdi-apple-keyboard-command"></i><span
                        className="d-none d-lg-inline-block">&nbsp;{t('sharedSavedCommands')}</span></Button>
                    <Button disabled={this.isButtonDisabled()} className="mr-1"
                            onClick={() => this.openSelectionDialog('maintenance')} size="sm"><i
                        className="mdi mdi-wrench"></i><span
                        className="d-none d-lg-inline-block">&nbsp;{t('sharedMaintenance')}</span></Button>
                </div>
            </div>);
    }

    render() {
        return <React.Fragment>
            {this.renderToolbar()}
            {this.renderSelectionDialog()}
        </React.Fragment>
    }
}