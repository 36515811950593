import BaseService from './ApiService';

export class PasswordResetService extends BaseService {
    constructor() {
        super();
        this.baseUrl = '/password';
    }

    async requestPasswordReset(email) {
        const params = new URLSearchParams();
        params.append('email', email);
        params.append("flavour","nadzor")
        const {data} = await this.api.post(this.baseUrl + "/v2/reset", params, {
            headers: {
                'content-type': "application/x-www-form-urlencoded"
            }
        });
    }

    async updatePassword(token,password) {
        const params = new URLSearchParams();
        params.append('token', token);
        params.append('password', password);
        const {data} = await this.api.post(this.baseUrl + "/update", params, {
            headers: {
                'content-type': "application/x-www-form-urlencoded"
            }
        });
    }

    async verifyCode(code,email) {
        const params = new URLSearchParams();
        params.append('code', code);
        params.append("email",email);
        const {data} = await this.api.post(this.baseUrl + "/verify-code", params, {
            headers: {
                'content-type': "application/x-www-form-urlencoded"
            }
        });
        return data;
    }
}

export default new PasswordResetService();