import React from 'react';
import {Dropdown, FormGroup, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {t} from '../../utils/Translator';
import {AppService, SessionService} from '../../services';
import {connect} from 'react-redux';
import Page from '../Page';


import {store} from '../../store';
import app from '../../store/app';
import {App, customReadOnlyUser} from '../../App';
import languages from "../../config/languages";

export class AppHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentModal: null,
            modalProps: {},
            menuOpen: false,
            lang: AppService.lang['code'].toUpperCase()
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.changeLang = this.changeLang.bind(this);

    }

    async logout() {
        try {
            await SessionService.logout();
        } catch (ex) {
        } finally {
            window.location.reload();
        }
    }

    changeLang(lang) {
        const params = new URLSearchParams(window.location.search);
        params.set('lang', lang);
        const host = "/";
        window.history.replaceState({}, '', `${host}?${params}`);
        window.location.reload(true);
    }

    showModal(config, props = {}) {
        store.dispatch(app.actions.showModal(config))
        //this.setState({ currentModal: config, modalProps: props })
    }

    hideModal() {
        this.setState({currentModal: null, modalProps: {}})
    }

    renderModal(config, props = {}) {
        const Component = config.component;
        return <Component {...props} onHide={() => this.hideModal()}/>
    }

    toggleMenu() {
        this.setState({menuOpen: !this.state.menuOpen})
    }

    render() {
        const lang = this.state.lang

        const admin = this.props.user.administrator;
        const readonly = AppService.getPreference('readonly', false);

        const configMenu = [
            {
                name: 'groups',
                title: t('settingsGroups'),
                icon: 'mdi mdi-google-circles-extended',
                enabled: App.App.userHasPermission('groups')
            },
            {
                name: 'server',
                title: t('settingsServer'),
                icon: 'mdi mdi-server',
                enabled: App.App.userHasPermission('server')
            },
            {
                name: 'drivers',
                title: t('sharedDrivers'),
                icon: 'mdi mdi-account-key',
                enabled: App.App.userHasPermission('drivers')
            },
            {
                name: 'geofences',
                title: t('sharedGeofences'),
                icon: 'mdi mdi-map-marker-radius',
                enabled: App.App.userHasPermission('geofences')
            },
            {
                name: 'notifications',
                title: t('sharedNotifications'),
                icon: 'mdi mdi-bell-ring',
                enabled: true
            },
            {
                name: 'calendars',
                title: t('sharedCalendars'),
                icon: 'mdi mdi-calendar',
                enabled: App.App.userHasPermission('calendars')
            },
            {
                name: 'savedCommands',
                title: t('sharedSavedCommands'),
                icon: 'mdi mdi-apple-keyboard-command',
                enabled: App.App.userHasPermission('commands')
            },
            {
                name: 'users',
                title: t('settingsUsers'),
                icon: 'mdi mdi-account-multiple',
                enabled: App.App.userHasPermission('users')
            },
            //todo reenable
            // {
            //     name: 'expenses',
            //     title: t('sharedExpenses'),
            //     icon: 'mdi mdi-account-multiple',
            //     enabled: true//App.App.userHasPermission('users')
            // },
            {
                name: 'maintenance',
                title: t('sharedMaintenance'),
                icon: 'mdi mdi-wrench',
                enabled: App.App.userHasPermission('maintenance')
            },
            {
                name: 'devices',
                title: t('deviceTitle'),
                icon: 'mdi mdi-car',
                enabled: App.App.userHasPermission('devices')
            },
            {
                name: 'customerDevices',
                title: t('customerDevices'),
                icon: 'mdi mdi-car',
                enabled: App.App.userHasPermission('customerDevices')
            },
            {
                name: 'statistics',
                title: t('statisticsTitle'),
                icon: 'mdi mdi-chart-bar',
                enabled: App.App.userHasPermission('statics')
            },
        ];

        // if (App.App.user.administrator)
            configMenu.push({
                name: 'computedAttributes',
                title: t('sharedComputedAttributes'),
                icon: 'mdi mdi-cast',
                enabled: App.App.userHasPermission('computed-attributes')
            })

        const userMenu = [
            {
                name: 'account',
                title: t('settingsUser'),
                icon: 'mdi mdi-account-circle',
                enabled: App.App.userHasPermission('account')
            },
            {
                name: 'accountSettings',
                title: t('sharedPreferences'),
                icon: 'mdi mdi-settings',
                enabled: App.App.userHasPermission('preferences')
            },
        ]


        //const { intl } = this.props;
        return (
            <React.Fragment>
                <Navbar className="app-header p-0 m-0" fixed="top">
                    <Navbar.Brand>
                        <img src="img/logo-dark.png" alt="IQTracking" height="30px" />
                    </Navbar.Brand>

                    <div className="ml-auto right-content">

                        <div className="flexbox-container"
                             style={{
                                 display: "flex",
                                 flexDirection: "row"
                             }}>

                            {this.state.menuOpen ? null :
                                <FormGroup className="mr-3">

                                    <Dropdown block="true">
                                        <Dropdown.Toggle block={true} variant="outline-dark" size="sm">
                                            {lang}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="scrollable-menu">
                                            {Object.keys(languages).map(k => {
                                                return <Dropdown.Item key={k}
                                                                      onClick={() => this.changeLang(k)}>{languages[k]['name']}</Dropdown.Item>
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </FormGroup>
                            }

{ App.App.user.id === customReadOnlyUser ? null :
                            <button
                                className={"mb-2 hamburger hamburger--slider " + (this.state.menuOpen ? 'is-active' : '')}
                                onClick={this.toggleMenu} type="button">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                            </button>
    }

                        </div>
                        {/*<NavDropdown title={<i className="mdi mdi-settings"></i>}>*/}
                        {/*    <div>*/}
                        {/*        {*/}
                        {/*            Object.values(configMenu).sort((a, b) => a.title > b.title ? 1 : -1).map(o => {*/}
                        {/*                return (*/}
                        {/*                    o.enabled ? <NavDropdown.Item key={o.name} onClick={() => this.showModal(o)}><i className={o.icon}></i> {o.title}</NavDropdown.Item> : null*/}
                        {/*                );*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</NavDropdown>*/}
                        {/*<NavDropdown title={<i className="mdi mdi-account-circle"></i>}>*/}
                        {/*    <div>*/}
                        {/*        {*/}
                        {/*            Object.values(userMenu).map(o => {*/}
                        {/*                return (*/}
                        {/*                    <NavDropdown.Item key={o.name} onClick={() => this.showModal(o)}><i className={o.icon}></i> {o.title}</NavDropdown.Item>*/}
                        {/*                );*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*        <NavDropdown.Divider />*/}
                        {/*        <NavDropdown.Item onClick={this.logout}><i className="mdi mdi-logout-variant"></i> {t('loginLogout')}</NavDropdown.Item>*/}
                        {/*    </div>*/}
                        {/*    </NavDropdown>*/}

                    </div>

                </Navbar>

                <Nav className={"main-menu " + (this.state.menuOpen ? 'active' : '')}>
                    <div className="overlay" onClick={this.toggleMenu}></div>
                    <div className="content" onClick={this.toggleMenu}>
                        <div className="header">
                            <img src="img/logo-dark.svg" alt="IQTracking" height="30px"/>
                        </div>
                        <div className="menu">
                            {
                                Object.values(userMenu).map(o => {
                                    return (
                                        o.enabled ? <NavDropdown.Item key={o.name} onClick={() => this.showModal(o)}><i
                                            className={o.icon}></i> {o.title}</NavDropdown.Item> : null
                                    );
                                })
                            }
                            <hr/>
                            {
                                Object.values(configMenu).sort((a, b) => a.title > b.title ? 1 : -1).map(o => {
                                    return (
                                        o.enabled ? <NavDropdown.Item key={o.name} onClick={() => this.showModal(o)}><i
                                            className={o.icon}></i> {o.title}</NavDropdown.Item> : null
                                    );
                                })
                            }
                            <hr/>
                            <NavDropdown.Item onClick={this.logout}><i
                                className="mdi mdi-logout-variant"></i> {t('loginLogout')}</NavDropdown.Item>
                        </div>
                    </div>
                </Nav>


                {/*this.state.currentModal ? this.renderModal(this.state.currentModal, this.state.modalProps) : null*/}
                {this.state.currentModal ? <Page open={true} onHide={() => this.setState({currentModal: null})}
                                                 title={<h1 className="title"><i
                                                     className={this.state.currentModal.icon}></i> {this.state.currentModal.title}
                                                 </h1>}>
                    {this.renderModal(this.state.currentModal, this.state.modalProps)}
                </Page> : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user
})


export default connect(mapStateToProps, null)(AppHeader);